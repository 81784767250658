<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-if="isFinish">
    <template v-if="datas.pages === 0">
      <div class="u-flex-col u-col-center bor-bot not-box">
        <img
          class="not-applied"
          src="../assets/images/cardApplication/not_applied.png"
          alt=""
        />
        <div class="not-font">您还未申请过信用卡哦</div>
      </div>
      <div class="more-box">
        <div class="more-tit">为您推荐一下爆款卡片</div>
        <BankItem
          v-for="(item, index) in bankList"
          :key="index"
          :bankInfo="item"
          @click="handleClick(item.bankType)"
        />
      </div>
    </template>
    <van-list
      v-else
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell
        v-for="(item, index) in datas.list"
        :key="index"
        class="list-item u-flex u-row-between u-col-top"
        :title="item.bankName"
        :label="`申请时间：${item.createTime}`"
      >
        <van-tag class="pass" v-if="item.status === 2">
          {{ item.statusTxt }}
        </van-tag>
        <van-tag class="approval" v-if="item.status === 1">
          {{ item.statusTxt }}
        </van-tag>
        <van-tag class="refuse" v-if="item.status === 3">
          {{ item.statusTxt }}
        </van-tag>
      </van-cell>
    </van-list>
  </van-pull-refresh>
</template>

<script>
import creditCard from "@/utils/creditCard";
import * as Api_CardApplication from "@/api/cardApplication";

export default {
  name: "ProgressQuery",
  data() {
    return {
      bankList: creditCard,
      datas: {
        list: [],
        pages: 0,
      },
      isFinish: false,
      loading: false,
      finished: false,
      refreshing: false,
      params: {
        pageNum: 0,
        pageSize: 10,
      },
      applyParams: {
        source: 1,
        bankType: null,
      },
    };
  },
  mounted() {
    this.applyRecords();
  },
  methods: {
    onLoad() {
      setTimeout(() => {
        this.params.pageNum++;
        this.applyRecords();
      }, 1000);
    },

    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.datas.list = [];
      this.params.pageNum = 0;
      this.onLoad();
    },

    applyRecords() {
      Api_CardApplication.applyRecords(this.params).then((response) => {
        this.isFinish = true;
        this.loading = false;
        if (this.refreshing) {
          this.refreshing = false;
        }
        this.datas.pages = response.data.pages;
        if (this.params.pageNum <= this.datas.pages) {
          this.datas.list = [...this.datas.list, ...response.data.list];
        } else {
          this.finished = true;
        }
      });
    },

    handleClick(index) {
      this.applyParams.bankType = index;
      Api_CardApplication.cardApply(this.applyParams).then((response) => {
        if (response.code === 200) {
          window.location.href = response.data.applyUrl;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bor-bot {
  border-bottom: 0.24rem solid #f8f8f8;
}
.more-box {
  padding-bottom: 0.6rem;
}
.more-tit {
  padding: 0.36rem 0.6rem 0;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #3a3a3a;
  line-height: 0.44rem;
}
.not-box {
  padding: 0.96rem 0;
}
.not-applied {
  display: block;
  width: 1.76rem;
  height: 1.66rem;
}
.not-font {
  font-size: 0.28rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #9e9e9e;
  line-height: 0.4rem;
  margin-top: 0.24rem;
}
.list-item {
  padding: 0.34rem 0.36rem;

  .pass {
    background: rgba(15, 202, 124, 0.12);
    color: rgba(15, 202, 124, 1);
  }

  .approval {
    background: rgba(250, 161, 72, 0.12);
    color: rgba(250, 161, 72, 1);
  }

  .refuse {
    background: rgba(249, 90, 98, 0.12);
    color: rgba(249, 90, 98, 1);
  }

  ::v-deep {
    .van-cell__title {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: #3a3a3a;
      line-height: 0.44rem;
    }
    .van-cell__label {
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #c3c2c6;
      line-height: 0.34rem;
    }
    .van-cell__value {
      flex: none;
    }
  }
}
</style>
